var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Edit Anggota ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"NISN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NISN","label-for":"nisn","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"nisn","reduce":function (nisn) { return nisn.nisn; },"placeholder":"Pilih Siswa","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listSiswa,"label":"name"},on:{"change":function($event){return _vm.onChange($event)}},model:{value:(_vm.dataStudent.nisn),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nisn", $$v)},expression:"dataStudent.nisn"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Nama","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Nama"},model:{value:(_vm.dataStudent.name),callback:function ($$v) {_vm.$set(_vm.dataStudent, "name", $$v)},expression:"dataStudent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ID Anggota","label-for":"librarynumber"}},[_c('validation-provider',{attrs:{"name":"librarynumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"librarynumber","state":errors.length > 0 ? false : null,"placeholder":"ID Anggota"},model:{value:(_vm.dataStudent.librarynumber),callback:function ($$v) {_vm.$set(_vm.dataStudent, "librarynumber", $$v)},expression:"dataStudent.librarynumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.dataStudent.email),callback:function ($$v) {_vm.$set(_vm.dataStudent, "email", $$v)},expression:"dataStudent.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"No TLP/WA","label-for":"handphone"}},[_c('validation-provider',{attrs:{"name":"No TLP/WA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"handphone","state":errors.length > 0 ? false : null,"placeholder":"No TLP/WA"},model:{value:(_vm.dataStudent.handphone),callback:function ($$v) {_vm.$set(_vm.dataStudent, "handphone", $$v)},expression:"dataStudent.handphone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Notes","label-for":"notes"}},[_c('validation-provider',{attrs:{"name":"notes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"notes","state":errors.length > 0 ? false : null,"placeholder":"Notes"},model:{value:(_vm.dataStudent.notes),callback:function ($$v) {_vm.$set(_vm.dataStudent, "notes", $$v)},expression:"dataStudent.notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }